import React from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import Container from "../components/global/container";
import { ImageBuilder } from "../components/global/image-builder";

const NotFoundPage = () => (
  <LayoutContainer>
    <PageHeadMeta title="Not found" />
    <Container className="py-12">
      <div
        style={{ minHeight: "50vh", marginTop: "20vh" }}
        className="text-center"
      >
        <h1
          style={{ fontSize: "9rem", lineHeight: "6rem" }}
          className="font-semibold flex items-center justify-center"
        >
          4
          <ImageBuilder
            src="/static/img/emoji-eye.svg"
            alt="emoji eye"
            className="inline h-28 mb-2"
          />
          4
        </h1>
        <ImageBuilder
          src="/static/img/emoji-spock-hand.svg"
          alt="emoji spock hand"
          className="inline-block my-4 sm:my-0 sm:mr-96"
        />
        <h3 className="text-3xl font-semibold w-3/4 sm:w-full mx-auto">
          This isn't the page you're looking for.
        </h3>
        <h4 className="text-lg text-gray-800 mt-24">Sorry, wrong reference.</h4>
      </div>
    </Container>
  </LayoutContainer>
);

export default NotFoundPage;
